<template>
  <div class="container">
    <!--    Logo Div-->
    <div class="row">
      <div class="col-12 text-center pt-3">
        <router-link :to="{ name: 'Home' }">
          <img id="logo" src="../../assets/icon2.png" />
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 justify-content-center d-flex flex-row pt-5">
        <div id="signup-div" class="flex-item border">
          <h2 class="pt-4 pl-4">Kargo Bilgilerini Giriniz</h2>
          <form @submit="saveCargoInfo" class="pt-4 pl-4 pr-4">
            <div class="form-group">
              <label>Kargo Numarası</label>
              <input
                placeholder="Kargo Teslimat Numarası"
                type="text"
                class="form-control"
                v-model="cargoNo"
                required
              />
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group ">
                  <label>Kargo Firması:</label>
                  <input
                    placeholder="PTT Kargo,Yurtiçi Kargo vs"
                    type="name"
                    class="form-control"
                    v-model="cargoCompany"
                    required
                  />
                </div>
              </div>
            </div>
            <p class="text-center">
            <button type="submit" class="btn btn-info">
              Kaydet
            </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signup",
  props: ["baseURL"],
  data() {
    return {
      orderID: null,
      key: null,
      admin_token: null,
      cargoCompany: null,
      cargoNo: null,
    };
  },
  methods: {
    acceptNumber() {
      var x = this.telephone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.telephone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async saveCargoInfo(e) {
      this.orderID = this.$route.params.id;
      console.log(this.orderID);
      e.preventDefault();
      // if the password matches
      // make the post body
      const user = {
        key: this.key,
        admin_token: this.admin_token,
        orderNo: this.orderID,
        cargoCompany: this.cargoCompany,
        cargoNo: this.cargoNo,
      };

      // call the API
      await axios({
        method: "post",
        url: `${this.baseURL}Order/orderDeliveryInfo.php?key=123`,
        data: JSON.stringify(user),
      })
        .then((response) => {
          console.log(response)
          if(response.data == 1005){
            swal({
              text: "Kargo Bilgileri Başarıyla Güncellendi",
              icon: "info",
              closeOnClickOutside: false,
            });
                 setTimeout(() => {
                 this.$router.push({ name: 'AdminOrderDetail' , params: {id: this.orderID}});
                }, 1500);
          }else if(true == response.data){
            swal({
              text: "Kargo Bilgileri Başarıyla Kaydedildi",
              icon: "success",
              closeOnClickOutside: false,
            });
            setTimeout(() => {
                 this.$router.push({ name: 'AdminOrderDetail' , params: {id: this.orderID}});
                }, 1500);
          }else{
            swal({
              text: "Bir Hatayla Karşılaşıldı",
              icon: "error",
              closeOnClickOutside: false,
            });
          }
          //this.$router.push({ name: 'AdminOrderDetail' , params: {id: this.orderID}});

        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.orderID = this.$route.params.id;
    this.admin_token = localStorage.getItem("admin_token");
    this.key = localStorage.getItem("key");
  },
};
</script>

<style scoped>
.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signup-div {
    width: 40%;
  }
}
</style>
