<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Ürün Düzenle</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-md-6 px-5 px-md-0">
        <form v-if="product">
          <div class="form-group">
            <label>Kategory</label>
            <select class="form-control" v-model="product.categoryId" required>
              <option
                v-for="category of categories"
                :key="category.id"
                :value="category.id"
                >{{ category.categoryName }}</option
              >
            </select>
          </div>
          <div class="form-group">
            <label>İsim</label>
            <input
              type="text"
              class="form-control"
              v-model="product.name"
              required
            />
          </div>
          <div class="form-group">
            <label>Tanım</label>
            <input
              type="text"
              class="form-control"
              v-model="product.description"
              required
            />
          </div>
          <div class="form-group">
            <label>Fotoğraf Url </label>
            <input
              type="url"
              class="form-control"
              v-model="product.imageURL"
              required
            />
          </div>
          <div class="form-group">
            <label>Fiyat</label>
            <input
              type="number"
              class="form-control"
              v-model="product.price"
              required
            />
          </div>
          <button type="button" class="btn btn-primary" @click="editProduct">
            Kaydet
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button type="button" class="btn btn-warning" @click="hangerProduct">
            Askıya Al
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button type="button" class="btn btn-danger" @click="deleteProduct">
            SİL
          </button>
        </form>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: null,
      admin_token: "",
    };
  },
  props: ["baseURL", "products", "categories"],
  methods: {
    async editProduct() {
      const editProduct = {
        productName: this.product.name,
        description: this.product.description,
        imageUrl: this.product.imageURL,
        price: this.product.price,
        categoryId: this.product.categoryId,
        productId: this.product.id,
      };
      await axios({
        method: "post",
        url:
          `${this.baseURL}Product/updateProduct.php?key=123`,
        data: JSON.stringify(editProduct),
      })
        .then((res) => {
          //sending the event to parent to handle
          this.$emit("fetchData");
          this.$router.push({ name: "AdminProduct" });
          swal({
            text: "Ürününüz Başarıyla Güncellendi",
            icon: "success",
            closeOnClickOutside: false,
          });
        })
        .catch((err) => console.log("err", err));
    },
    async deleteProduct() {
      // https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Product/deleteProduct.php?key=123
      const deleteProduct = {
        product_id: this.product.id,
        admin_token: this.admin_token,
      };
      await axios({
        method: "post",
        url:
          `${this.baseURL}Product/deleteProduct.php?key=123`,
        data: JSON.stringify(deleteProduct),
      })
        .then((res) => {
          
          if (res.data == 1) {
            //sending the event to parent to handle
            this.$emit("fetchData");
            setTimeout(() => this.$router.push({ name: "AdminProduct" }), 700);
            swal({
              text: "Ürününüz Başarıyla Silindi",
              icon: "success",
              closeOnClickOutside: false,
            });
          }
          else if(res.data == 2003){
            swal({
              text: "Ürün Siparişlerinizin İçeriğinde Bulunmaktadır. Ürün Teslim Edilmeden Ürünü Silemezsiniz!",
              icon: "error",
              closeOnClickOutside: false,
            });
          } else {
            swal({
              text: "Bir Hata Oluştu. Tekrar Deneyiniz.",
              icon: "error",
              closeOnClickOutside: false,
            });
          }
        })
        .catch((err) => console.log("err", err));
    },
    async hangerProduct() {
      // https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Product/deleteProduct.php?key=123
      const hangerProduct = {
        product_id: this.product.id,
        admin_token: this.admin_token,
      };
      await axios({
        method: "post",
        url:
         `${this.baseURL}Product/hangerProduct.php?key=123`,
        data: JSON.stringify(hangerProduct),
      })
        .then((res) => {
          if (res.data == 1) {
            //sending the event to parent to handle
            this.$emit("fetchData");
            setTimeout(() => this.$router.push({ name: "AdminProduct" }), 700);
            swal({
              text: "Ürününüz Başarıyla Askıya Alındı",
              icon: "success",
              closeOnClickOutside: false,
            });
          }else if(res.data == 2003){
      swal({
              text: "Ürün Siparişlerinizin İçeriğinde Bulunmaktadır. Ürün Teslim Edilmeden Ürünü Askıya Alamazsınız!",
              icon: "error",
              closeOnClickOutside: false,
            });
          } else {
            swal({
              text: "Bir Hata Oluştu. Tekrar Deneyiniz.",
              icon: "error",
              closeOnClickOutside: false,
            });
          }
        })
        .catch((err) => console.log("err", err));
    },
  },
  mounted() {
    if (!localStorage.getItem("admin_token")) {
      this.$router.push({ name: "Signin" });
      return;
    }
    this.admin_token = localStorage.getItem("admin_token");
    this.id = this.$route.params.id;
    this.product = this.products.find((product) => product.id == this.id);
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}
</style>
