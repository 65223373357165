<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Kategoriyi Düzenle</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-md-6 px-5 px-md-0">
        <form v-if="category">
          <div class="form-group">
            <label>Kategori Adı</label>
            <input type="text" class="form-control" v-model="category.categoryName" required>
          </div>
          <div class="form-group">
            <label>Açıklama</label>
            <input type="text" class="form-control" v-model="category.description" required>
          </div>
          <div class="form-group">
            <label>Fotoğraf Url</label>
            <input type="url" class="form-control" v-model="category.imageUrl" required>
          </div>
          <button type="button" class="btn btn-primary" @click="editCategory">Kaydet</button>
        </form>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      category: null
    }
  },
  props : ["baseURL", "categories"],
  methods : {
    async editCategory() {
      const editCategory = {
        categoryName : this.category.categoryName,
        description : this.category.description,
        imageUrl : this.category.imageUrl,
        categoryId : this.id,
      }
      delete this.category["products"]
      await axios({
        method: 'post',
        url: "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Category/updateCategory.php?key=123",
        data : JSON.stringify(editCategory),
    
      })
      .then(res => {
          //sending the event to parent to handle
        this.$emit("fetchData");
        this.$router.push({name:'AdminCategory'});
        swal({
          text: "Kategori Başarıyla Güncellendi",
          icon: "success",
          closeOnClickOutside: false,
        });
      })
      .catch(err => console.log(err));
    }
  },
  mounted() {
    if (!localStorage.getItem('admin_token')) {
      this.$router.push({name : 'Signin'});
      return;
    }
    this.id = this.$route.params.id;
    this.category = this.categories.find(category => category.id == this.id);
    console.log('category', this.category);
  }
}
</script>

<style scoped>
h4 {
  font-family: 'Roboto', sans-serif;
  color: #484848;
  font-weight: 700;
}
</style>
