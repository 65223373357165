<template>
  <div class="wow fadeInUp">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <!-- 4 saniye -->
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          v-for="(item, index) in result"
          :key="index"
          :data-slide-to="index"
          :class="{ active: true, active: index == 0 }"
        ></li>
      </ol>
      <!-- aşağıda bulunan kutucuk  -->

      <div class="carousel-inner" style="height: 500px">
        <div
          style="height: 100%"
          class="carousel-item"
          v-for="(item, index) in result"
          :key="index"
          :class="{ active: true, active: index == 0 }"
          v-bind:style="{
            backgroundImage:
              'url(' +
              'https://www.img.1sepetkayisi.aslanyazilimm.com/superAdminImg/' +
              item.img_url +
              ')',
          }"
        >
          <div
            class="carousel-caption d-md-block"
            style="background-color: #0b0a0a78"
          >
            <h2
              style="font-size: 2rem"
              class="display-4"
              id="sliderhood"
              v-html="'<div>' + item.title + '</div>'"
            ></h2>
            <!-- <p v-html="'<div>' + result.content + '</div>'"></p> -->
            <p
              class="lead"
              id="slidertext"
              v-html="'<div>' + item.content + '</div>'"
            ></p>

            <!-- <p class="lead" id="slidertext">{{ item.content }}</p> -->
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../components/Product/ProductBox";
import CategoryBox from "../components/Category/CategoryBox";
export default {
  name: "Home",
  components: { ProductBox, CategoryBox },
  props: ["baseURL", "products", "categories"],
  data() {
    return {
      result: [],
    };
  },
  mounted() {
    $("#myCarousel").on("slide.bs.carousel", function() {});
    $(".carousel").carousel();
  },
  created: function() {
    $(".carousel").carousel({
      interval: 2000,
    });
    let datas = [];
    let dataUrl =
      "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Slider/getAllSlider.php?key=123";
    return axios
      .get(dataUrl)
      .then((response) => {
        //console.log(response);
        datas = response.data.data;

        this.result = datas;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>

<style>
.page-holder {
  min-height: 100vh;
}

.bg-cover {
  background-size: cover !important;
}

#background-div {
  background: url(../assets/homepageMain2.png);
}

#heading {
  padding-top: 150px;
  text-align: center;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  opacity: 0.8;
  font-family: "Josefin Sans", sans-serif;
}

#content {
  opacity: 0.8;
}

h2 {
  font-family: "Josefin Sans", sans-serif;
}
</style>
