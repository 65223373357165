<template>
  <div class="adminPanel container">
    <router-link  :to="{ name: 'AdminOrder' }">
      <button class="button" type="button" >Siparişler</button>
    </router-link>
    <router-link  :to="{ name: 'AdminProduct' }">
      <button class="button" type="button" >Ürünler</button>
    </router-link>
    <!-- <router-link :to="{ name: 'AdminCategory' }">
      <button type="button" class="btn btn-primary btn-lg">Kategoriler</button>
    </router-link> -->
    <router-link  :to="{ name: 'HangerProduct' }">
      <button class="button" type="button" >Askıdaki Ürünler</button>
    </router-link>
    <router-link  :to="{ name: 'Gallery' }">
   <button class="button" type="button" >Fotoğraf Galerisi</button>
    </router-link>
    <button class="button" type="button" @click="signout">Çıkış Yap</button>
  </div>
</template>

<script>
export default {
  mounted() {
    if (!localStorage.getItem('admin_token')) {
      this.$router.push({ name: 'Signin' });
    }
  }, methods: {
    signout() {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_token");
      localStorage.removeItem("key");
      this.token = null;
      this.$emit("resetCartCount");
      this.$router.push({ name: "Home" });
      swal({
        text: "Admin Çıkış İşlemi Başarıyla Gerçekleştirildi!",
        icon: "success",
        closeOnClickOutside: false,
      });
    },
  },
}
</script>

<style>
.adminPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttona {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #ab851e; /* Green */
  border: none;
  color: white;
  width: 50%;
  padding: 15px 32px;
  margin: 35px 0;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
}
.button {
  margin:20px 0;
  width: 300px;
  display: inline-block;
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
</style>