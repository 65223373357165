<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Ürünleriniz</h4>
        <router-link id="add-product" :to="{ name: 'AddProduct' }" v-show="$route.name == 'AdminProduct'">
          <button class="btn">Yeni Ürün Ekle</button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div v-for="product of productList" :key="product.id"
        class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex">
        <ProductBox :product="product"> </ProductBox>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../../components/Product/ProductBox";
export default {
  data() {
    return {
      product: [],
      productList: [],
    };
  },
  name: "Product",
  components: { ProductBox },
  props: ["baseURL", "products"],
  mounted() {
    if (this.$route.name == "AdminProduct" && !localStorage.getItem("admin_token")) {
      this.$router.push({ name: "Signin" });
    }
    for (var i = 0; i < this.products.length; i++) {
      var productProperty = [];
      productProperty.push(this.products[i])
      var productKey = productProperty.find(product => product.admin_id == localStorage.getItem("key"));
      this.product.push(productKey)
    }
    for (var i = 0; i < this.product.length; i++)
      if ((typeof (this.product[i]) !== 'undefined') && (this.product[i] !== null)) {
        this.productList.push(this.product[i])
      }

    console.log(this.productList)

  },

};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

#add-product {
  float: right;
  font-weight: 500;
}
</style>
