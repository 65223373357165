<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Galeri</h4>
        <router-link id="add-image" :to="{ name: 'AddImage' }">
          <button class="btn">Yeni Fotoğraf Ekle</button>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div
        v-for="image of images"
        :key="image.name"
        class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
      >
        <ImageBox :image="image"></ImageBox>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBox from "../../components/Image/ImageBox.vue";
export default {
  name: "Gallery",
  components: { ImageBox },
  data() {
    return {
      images: null,
    };
  },
  props: ["baseURL"],
  methods: {
    async fetchImages() {
      const user = {
        token: this.admin_token,
      };
      await axios({
        method: "post",
        url:
          `${this.baseURL}Gallery/getAllGallery.php?key=123`,
        data: JSON.stringify(user),
      }).then((res) => {
        if(res.data == false){
             swal({
              text: "Galerinizde Fotoğraf Bulunmuyor Sağ Üst Köşeden Fotoğraf Yükleyebilirsiniz",
              icon: "info",
              closeOnClickOutside: false,
            });
        }
        this.images = res.data;
      });
    },
  },
  mounted() {
    if (!localStorage.getItem("admin_token")) {
      this.$router.push({ name: "Signin" });
      return;
    }
    this.admin_token = localStorage.getItem("admin_token");
    this.fetchImages();
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

#add-image {
  float: right;
  font-weight: 500;
}
</style>
