<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <!--    Logo-->
    <router-link class="navbar-brand" :to="{ name: 'Home' }">
      <img style="max-height:20px;max-width:20px" src="../assets/apricot.svg" />

      <img id="logo" src="../assets/1sepet.png" />
    </router-link>

    <!--    Burger Button-->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!--      Search Bar-->
      <div style="margin:auto" aria-labelledby="navbarDropdown">
        <input
          style="width:250px;border-radius:0.25rem"
          type="text"
          v-model="searchString"
          placeholder="Ürün Veya Marka Giriniz"
        />

        <div style="width: 250px;position: absolute; z-index:1;">
          <ul class="searchCss" style="">
          <!-- bura kazayla çalıştı çaktırma -->
            <li v-for="result in results" :key="result.id">
            <hr />
              <router-link @click="count" 
                style="padding-right: 5px;padding-left: 5px;font-size: 15px;color: black;"
                id="/product/show/"
                :to="{ name: 'ShowDetails', params: { id: result.id } }"
              >
                {{ result.name + "~" + result.description}}
              </router-link>
              
            </li>
          </ul>
        </div>
      </div>

      <!-- <form class="form-inline ml-auto mr-auto">
        <div class="input-group">
          <input
            size="70"
            v-model="input"
            type="text"
            class="form-control"
            placeholder="Aradığınız ürün Veya Markayı Yazınız"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
         <button @click="getFilteredUsers">Filter using selected date</button>

          <div class="input-group-prepend">
            <span @click="getFilteredUsers" class="input-group-text" id="search-button-navbar">
 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </span>
          </div>
 
        </div>
      </form> -->

      <!--      DropDowns-->
      <div class="control-section"></div>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link text-light" :to="{ name: 'Showproduct' }"
            >Ürünler</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link text-light" :to="{ name: 'Category' }"
            >Kategoriler</router-link
          >
        </li>
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link text-light dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Keşfet
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" :to="{ name: 'Home' }"
              >AnaSayfa</router-link
            >
            <router-link class="dropdown-item" :to="{ name: 'Showproduct' }"
              >Ürünler</router-link
            >
            <router-link class="dropdown-item" :to="{ name: 'Category' }"
              >Kategoriler</router-link
            >
          </div>
        </li> -->
        <li class="nav-item">
          <router-link class="nav-link text-light" :to="{ name: 'Wishlist' }"
            >Favorilerim</router-link
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link text-light dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Hesap
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!-- <router-link
              class="dropdown-item"
              v-if="!token"
              :to="{ name: 'Signin' }"
              >Favorilerim</router-link
            >
            <router-link class="dropdown-item" v-else :to="{ name: 'Wishlist' }"
              >Favorilerim</router-link
            > -->
            <!-- <router-link class="dropdown-item" :to="{ name: 'Admin' }"
              >Admin</router-link
            > -->
            <router-link
              class="dropdown-item"
              v-if="!token"
              :to="{ name: 'Signin' }"
              >Giriş Yap</router-link
            >
            <router-link
              class="dropdown-item"
              v-if="!token"
              :to="{ name: 'Signup' }"
              >Üye Ol</router-link
            >
            <button
              class="dropdown-item"
              v-if="token"
              href="#"
              @click="signout"
            >
              Çıkış Yap
            </button>
            <button
              class="dropdown-item"
              v-if="admin_token"
              href="#"
              @click="signoutadmin"
            >
             Çıkış Yap
            </button>
            <router-link
              class="dropdown-item"
              v-if="token"
              :to="{ name: 'MyProfile' }"
              >Profil Ayarlarım</router-link
            >
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link text-light dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Siparişleriniz
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link
              class="dropdown-item"
              :to="{ name: 'Order' }"
              >Güncel Siparişlerim</router-link
            >
            <router-link
              class="dropdown-item"
              :to="{ name: 'PassOrder' }"
              >Geçmiş Siparişlerim</router-link
            >
          </div>
        </li>

        <!-- <li class="nav-item">
          <router-link class="nav-link text-light" :to="{ name: 'Order' }"
            >Siparişleriniz</router-link
          >
        </li> -->
        <li class="nav-item">
          <div id="cart">
            <span id="nav-cart-count">{{ cartCount }}</span>
            <router-link class="text-light" :to="{ name: 'Cart' }"
              ><i class="fa fa-shopping-cart" style="font-size:36px"></i
            ></router-link>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "Navbar",
  props: ["cartCount", "products"],
  data() {
    return {
      searchString: "",
      results: [],
      token: null,
      product: [],
      admin_token: null,
    };
  },
  
  watch: {
    searchString: debounce(function() {
      this.search();
    }, 250),
      '$route' () {
      $('#navbarSupportedContent').collapse('hide');
    }
  },
  methods: {
    async getFilteredUsers() {
      // https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Product/searchProduct.php?key=123&keyword=ıuy
      // 'https://yourapi.com/users?filter.date=${this.selectedDate}'
      const filteredUsers = await fetch(
        `${this.baseURL}Product/searchProduct.php?key=123&keyword=ıuy`
      );
      this.users = await filteredUsers.json();
    },
    search: function() {
      // (`/search?query=${this.searchString}`)
      if (this.searchString.length > 0) {
        axios
          .get(
            `https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Product/searchProduct.php?key=123&keyword=${this.searchString}`
          )
          .then((response) => {
            // this.results = response.data;
            var deger = response.data.length;
            if (deger > 8) {
              this.results = response.data.slice(0, 8);
            } else {
              this.results = response.data;
            }
            console.log(response.data.length);
            console.log(response.data);
          });
      } else {
        this.results = null;
      }
    },
    signout() {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_token");
      localStorage.removeItem("data");
      localStorage.removeItem("key");
      localStorage.removeItem("id");

      this.token = null;
      this.$emit("resetCartCount");
      this.$router.push({ name: "Home" });
      swal({
        text: "Çıkış İşlemi Başarıyla Gerçekleştirildi!",
        icon: "success",
        closeOnClickOutside: false,
      });
    },
    signoutadmin() {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_token");
      localStorage.removeItem("data");
      localStorage.removeItem("key");
      localStorage.removeItem("id");

      this.token = null;
      this.$emit("resetCartCount");
      this.$router.go();
      swal({
        text: "Çıkış İşlemi Başarıyla Gerçekleştirildi!",
        icon: "success",
        closeOnClickOutside: false,
      });
    },
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.admin_token = localStorage.getItem("admin_token");
  },
};
</script>

<style scoped>
#logo {
  width: 150px;
}

.nav-link {
  color: rgba(255, 255, 255);
}

#search-button-navbar {
  background-color: #febd69;
  border-color: #febd69;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#nav-cart-count {
  background-color: red;
  color: white;
  border-radius: 40%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-left: 10px;
}
#cart {
  position: relative;
}
.form-control {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.searchCss {
    border-radius: 6px;
    background-color: white;

}
</style>
