<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h4 class="pt-3"><span style="color:#b58c1b">Geçmiş Sipariş Numarası: </span>  {{orderID}}</h4>
            </div>
        </div>

        <div v-for="(orderItem, index ) in orderItems" :key="index" class="row mt-2 pt-3 justify-content-around">
            <div class="col-1"></div>
            <div class="col-md-3 embed-responsive embed-responsive-16by9">
                <img :src="orderItem.imageURL" class="w-100 card-img-top embed-responsive-item">
                <hr/>
            </div>

            <div class="col-md-5 px-3">
                <div class="card-block px-3">
                    <h6 class="card-title" >{{orderItem.name}}</h6> 
                    <p id="item-price" class="mb-0 font-weight-bold">Adet Fiyatı {{orderItem.price}} ₺</p>
                    <p id="item-quantity" class="mb-0">Sipariş Adeti : {{orderItem.piece}}</p>
                    <p id="item-total-price" class="mb-0">
                        Toplam Tutar :<span class="font-weight-bold">{{ orderItem.piece * orderItem.price}} ₺</span>
                    </p>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
<hr>
        <div class="total-cost pt-2 text-center">
            <h5>Toplam Sipariş Tutarı : {{dataItems.totalCost}} ₺</h5>
            <h5>Sipariş Teslim Tarihi : {{dataItems.reg_date}}</h5>
            <h5>Sipariş Durumu : {{dataItems.orderStatus}}</h5>
            <h5>{{cargoInfo}}</h5>
        </div>
    </div>
  


</template>

<script>

export default {
    name:'OrderItems',
    props:["orderID","baseURL"],

data() {
    return {
        orderItems:[],
        dataItems:[],
        order: {},
        token: null,
        orderID: 0
    }
},

methods:{
    getOrder(){
         const cart = {
        token: this.token,
        orderNo: this.orderID,
        
      };
      axios({
        method: 'post',
        url: `${this.baseURL}Order/getAllPassOrderDetailUser.php?key=123`,
        data: JSON.stringify(cart),
      })
        .then((response) => {
            if(response.status === 200) {
               // console.log()
               // this.order = response.data
                this.dataItems = response.data
         
                for(let i=0;i<this.dataItems.totalItems;i++){
                   // console.log(this.orderItems[0])
                   this.orderItems[i] = this.dataItems[i];
                }
                if(this.dataItems.orderStatus == "Sipariş Kargoya Verildi"){
                    this.cargoInfo = "NOT : Kargo Bilgileriniz Mail Adresinize Gönderildi";
                }
              
                //console.log(this.dataItems)
            }
            },
            (err)=>{
                console.log(err)
            })
    }

},

mounted(){

    this.orderID = this.$route.params.id;
    this.token = localStorage.getItem('token');
    this.getOrder()
}
}

</script>

<style scoped>

    h4 {
        font-family: 'Roboto', sans-serif;
        color: #484848;
        font-weight: 700;
    }

    .embed-responsive .card-img-top {
        object-fit: cover;
    }

</style>
