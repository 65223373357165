<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Ürünleriniz</h4>
        <router-link
          id="add-product"
          :to="{ name: 'AddProduct' }"
          v-show="$route.name == 'AdminProduct'"
        >
          <button class="btn">Yeni Ürün Ekle</button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div
        v-for="product of productList"
        :key="product.id"
        class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
      >
        <div class="card h-100 w-100">
          <div class="embed-responsive embed-responsive-16by9">
            <!-- embed-responsive-item   aşağı clasa eklersen fotoğrafı responsive kullanır -->
            <img
              class="embed-responsive-item"
              :src="product.imageURL"
              alt="Product Image"
            />
          </div>
          <div class="card-body">
            <span
              :to="{ name: 'ShowDetails', params: { id: product.id } }"
              ><h5 class="card-title">{{ product.name }}</h5></span
            >
            <p class="card-text">{{ product.price }}<span>₺</span></p>
            <p class="card-text font-italic">
              {{ product.description.substring(0, 65) }}...
            </p>
          </div>
          <button type="button" class="btn btn-warning" @click="upProduct(product)">
            Geri Yükle
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../../components/Product/ProductBox";
export default {
  data() {
    return {
      admin_token: "",
      product: [],
      productList: [],
    };
  },
  name: "Product",
  components: { ProductBox },
  props: ["baseURL"],
    methods: {
    async upProduct(product) {
      const upProduct = {
        admin_token: this.admin_token,
        product_id: product.id,
      };

      await axios({
        method: "post",
        url:
         `${this.baseURL}Product/upProduct.php?key=123`,
        data: JSON.stringify(upProduct),
      })
        .then((res) => {
            if(res.data == 1){
            swal({
                text: "Ürününüz Başarıyla Geri Yüklendi",
                icon: "success",
                closeOnClickOutside: false,
          });
          setTimeout(() =>this.$router.go(0),500);
            }
          //sending the event to parent to handle
        //   this.$emit("fetchData");
        //   this.$router.push({ name: "AdminProduct" });

        })
        .catch((err) => console.log("err", err));
    }
    },
  mounted() {
    this.admin_token = localStorage.getItem("admin_token");

    const datas = {
      admin_token: this.admin_token,
    };

    axios({
      method: "post",
      url:
        `${this.baseURL}Product/getByAdminHangerProduct.php?key=123`,
      data: JSON.stringify(datas),
    })
      .then((response) => {
        if (response.status == 200) {
          this.productList = response.data;
        }
        console.log(this.productList);
      })
      .catch((err) => console.log(err));
    if (
      this.$route.name == "AdminProduct" &&
      !localStorage.getItem("admin_token")
    ) {
      this.$router.push({ name: "Signin" });
    }

    // for (var i = 0; i < this.product.length; i++)
    //   if (typeof this.product[i] !== "undefined" && this.product[i] !== null) {
    //     this.productList.push(this.product[i]);
    //   }
    // console.log(this.productList);
  },
};
</script>

<style scoped>
.embed-responsive {
  color: white;
}

a {
  text-decoration: none;
}

.card-title {
  color: #484848;
  font-size: 1.1rem;
  font-weight: 400;
}

.card-title:hover {
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
}

#edit-product {
  float: right;
}
</style>
