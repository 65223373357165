<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Yeni Fotoğraf Ekle</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-md-6 px-5 px-md-0 pt-5">
        <div class="form-group">
          <label for="myfile"
            >.jpeg veya .png formatında bir fotoğraf seçiniz :</label
          >
          <input
            type="file"
            id="myfile"
            class="form-control-file"
            @change="onFileSelected"
          />
        </div>
        <button type="button" class="btn btn-info" @click="onUpload">
          Yükle
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFile: null,
    };
  },
  props: ["baseURL"],
  methods: {
    onFileSelected(event) {
      //this will always update the selected file whenever user changes files
      this.selectedFile = event.target.files[0];
    },
    async onUpload() {
      if (!this.selectedFile) {
        swal({
          text: "Bir Dosya Seçiniz",
          icon: "warning",
          closeOnClickOutside: false,
        });
        return;
      }
      if (
        this.selectedFile.type !== "image/jpeg" &&
        this.selectedFile.type !== "image/png" &&
        this.selectedFile.type !== "image/jpg"
      ) {
        //file format is not correct
        swal({
          text: "Lütfen Bir Fotoğraf Seçiniz!",
          icon: "error",
          closeOnClickOutside: false,
        });
        return;
      }
      this.token =localStorage.getItem("admin_token");

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      var query ="https://www.img.1sepetkayisi.aslanyazilimm.com/UploadImg/admin_upload_img.php?token=" +
        this.token;
      await axios
        .post(query, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code == 8008) {
             console.log(res.data.code);
            swal({
              text: "Yetkisiz Giriş!",
              icon: "error",
              closeOnClickOutside: false,
           });
           this.$router.push({name : "Signin"});
          }
          if (res.data.result === true) {
          swal({
            text: "Fotoğraf Başarıyla Yüklendi!",
            icon: "success",
            closeOnClickOutside: false,
          });
          this.$router.push({name : "Gallery"});
          }
        })
        .catch((err) => {
          if(res.data.code == "8008")
 
          console.log(err);
        });
    },
  },
  mounted() {
    if (!localStorage.getItem("admin_token")) {
      this.$router.push({ name: "Signin" });
    }
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}
</style>
