<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Alınan Siparişler</h4>
      </div>
    </div>
    <!--        for each order display -->
    <div
      v-for="order in orderList"
      :key="order.pid"
      class="row mt-2 pt-3 justify-content-around"
    >
      <div class="col-2"></div>
      <div class="col-md-3 embed-responsive embed-responsive-16by9">
        <!--                display image in left-->
        <img
          v-bind:src="order.imageURL"
          class="w-100 card-img-top embed-responsive-item"
        />
      </div>
      <div class="col-md-5 px-3">
        <div class="card-block px-3">
          <h6 class="card-title">
            <router-link v-bind:to="'/admin/adminOrderDetail/' + order.id"
              >Sipariş Numarası : {{ order.id }}</router-link
            >
          </h6>
          <p class="mb-0 font-weight-bold">
            Ürün Adeti :{{ order.totalItems }}
          </p>
          <p id="item-price" class="mb-0 font-weight-bold">
            Sipariş Tutarı :{{ order.totalCost }}₺
          </p>
          <p id="item-total-price">Sipariş Tarihi : {{ order.reg_date }}</p>
          <p id="item-price" class="mb-0 font-weight-bold">
            Sipariş Durumu :{{ order.orderStatus }}
          </p>
        </div>
      </div>
      <div class="col-2"></div>
      <div class="col-12"><hr /></div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      token: null,
      orderList: [],
    };
  },
  props: ["baseURL"],
  name: "Order",
  methods: {
    // list of order histories
    listOrders() {
      const cart = {
        admin_token: this.admin_token,
        key: this.key,
      };
      axios({
        
        method: "post",
        url:
          `${this.baseURL}Order/getAllOrderIdAdmin.php?key=123`,
        data: JSON.stringify(cart),
      }).then(
        (response) => {
         // console.log(response.data);
          if ((response.data != 2002)) {
            this.orders = response.data;
            //console.log(response.data);
            // for each order populate orderList
            this.orders.forEach((order) => {
              this.orderList.push({
                id: order.orderNo,
                totalCost: order.totalCost,
                // get short date
                reg_date: order.reg_date,
                // get image of the first orderItem of the order
                imageURL: order.imageURL,
                // get total items
                totalItems: order.totalItems,

                orderStatus: order.orderStatus,
              });
            });
          } else {
            swal("İşlemde Olan Siparişiniz Bulunmamaktadır.", {
              icon: "info",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.admin_token = localStorage.getItem("admin_token");
    this.key = localStorage.getItem("key");

    this.listOrders();
  },
};
</script>

<style scoped>
h4,
h5 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

.embed-responsive .card-img-top {
  object-fit: cover;
}
</style>
