<template>
  <section class="vh-100">
    <div class="container py-5 h-100">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-md-8 col-lg-7 col-xl-6">
          <img
            src="https://img.freepik.com/free-vector/hacker-breaking-lock-get-access-personal-information-computer-isometric_1284-63723.jpg?t=st=1654401030~exp=1654401630~hmac=38f9d5346ee86e6fcd6e1d8eca628b19d5d5e249672de942f8307e1967179556&w=740"
            class="img-fluid"
            alt="Phone image"
          />
        </div>
        <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
          <form @submit="forgetPassword" class="pt-2 pl-2 pr-8">
            <div class="form-group">
              <label>Email Adresiniz</label>
              <input
                type="email"
                class="form-control"
                v-model="email"
                required
              />
            </div>
          </form>
          <p class="text-center">
            <button class="btn btn-primary mt-4 py-2" @click="forgetPassword()">Doğrulama Kodu Gönder</button>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ForgetPassword",
  props: ["baseURL"],
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async forgetPassword() {
      console.log("deneme");
      const emails = {
        email: this.email,
      };

      await axios({
        method: "post",
        url:
           `${this.baseURL}forgetPassword.php?key=123`,
        data: JSON.stringify(emails),
      })
        .then((res) => {
          console.log(res);
          if (res.data == "success") {
            //this.$router.push({path: this.$route.fullPath, query: {param: 'paramvalue} });
            console.log(this.email);
            localStorage.setItem("email", this.email);

            this.$router.push({
              name: "ForgetPasswordActivation",
            });
          }
        })
        .catch((err) => {
          swal({
            text: "Hatalı Kullanıcı Adı Veya Şifre",
            icon: "error",
            closeOnClickOutside: false,
          });
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("admin_token");
    localStorage.removeItem("key");
    localStorage.removeItem("admin_id");
    this.loading = false;
    this.password = null;
  },
};
</script>

<style>
.not-found {
  text-align: center;
}
.not-found h2 {
  font-size: 150px;
  margin-top: 30px;
}
.not-found h3 {
  font-size: 50px;
}
</style>
