<template>
<Slider/>
  <div id="home">
      

    <div id="start-shopping" class="container">
      <!-- burası kategorilerin olduğu yer -->
      <div class="row">
        <div class="col-12 text-left">
          <h2 class="pt-3">Popüler Kategoriler</h2>
        </div>
      </div>
      <div class="row">
        <div
          v-for="index in this.category_size"
          :key="index"
          class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
        >
          <CategoryBox :category="categories[index - 1]"> </CategoryBox>
        </div>
      </div>
    </div>
    <hr />
    <div class="container">
      <!-- burası ürünlerin olduğu yer -->
      <div class="row">
        <div class="col-12 text-left">
          <h2 class="pt-3">Popüler Ürünler</h2>
        </div>
      </div>
      <div class="row">
        <div
          v-for="index in this.product_size"
          :key="index"
          class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
        >
          <ProductBox :product="products[index - 1]"> </ProductBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Slider from "../components/Slider.vue";
import ProductBox from "../components/Product/ProductBox";
import CategoryBox from "../components/Category/CategoryBox";
export default {
  name: "Home",
  components: { ProductBox, CategoryBox , Slider },
  props: ["baseURL", "products", "categories"],
  data() {
    return {
      result: [],
      category_size: 0,
      product_size: 0,
    };
  },
  
  mounted() {
    //burası açılışta toplam uzunluğu çeker
    this.category_size = this.categories.length;
    // console.log(this.category_size);
    this.category_size = Math.min(9, this.category_size); //ekrana kaç tane kategory verileceği belirlenir

    this.product_size = this.products.length;
    // console.log(this.product_size);
    this.product_size = Math.min(50, this.product_size); //ekrana kaç tane ürün verileceği belirlenir
  },
  created: function() {
    let datas = [];
    let dataUrl =
       `${this.baseURL}Slider/getAllSlider.php?key=123`;
    return axios
      .get(dataUrl)
      .then((response) => {
        //console.log(response);
        datas = response.data.data;

        this.result = datas;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>

<style>
.page-holder {
  min-height: 100vh;
}

.bg-cover {
  background-size: cover !important;
}

#background-div {
  background: url(../assets/homepageMain2.png);
}

#heading {
  padding-top: 150px;
  text-align: center;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  opacity: 0.8;
  font-family: "Josefin Sans", sans-serif;
}

#content {
  opacity: 0.8;
}

h2 {
  font-family: "Josefin Sans", sans-serif;
}
</style>
