<template>
  <section class="vh-100">
    <div class="container py-5 h-100">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-md-8 col-lg-7 col-xl-6">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
            class="img-fluid"
            alt="Phone image"
          />
        </div>
        <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
          <form @submit="passwordCheck" class="pt-2 pl-2 pr-8">
            <div class="form-group">
              <label>Yeni Şifrenizi Giriniz</label>
              <input
                type="text"
                class="form-control"
                v-model="password"
                required
              />
            </div>
            <div class="form-group">
              <label>Şifrenizi Tekrar Giriniz</label>
              <input
                type="text"
                class="form-control"
                v-model="passwordConfirm"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary mt-4 py-2">
              Şifreyi Güncelle
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ForgetPassword",
  props: ["baseURL"],
  data() {
    return {
      email: null,
      activation_code: null,
      activation: null,
      password: null,
      passwordConfirm: null,
    };
  },
  methods: {
    async passwordCheck(e) {
      e.preventDefault();
      if (this.password === this.passwordConfirm) {
        // console.log("deneme");
        const emails = {
          email: this.email,
          activation: this.activation,
          password: this.password,
        };

        await axios({
          method: "post",
          url:
            `${this.baseURL}User/resetPassword.php?key=123`,
          data: JSON.stringify(emails),
        })
          .then((res) => {
            console.log(res);
            //localStorage.setItem("activation", res.data);
            if (res.data == true) {
              swal({
                text: "Şifreniz Başarıyla Değiştirildi",
                icon: "success",
                closeOnClickOutside: false,
              });
              localStorage.removeItem("email");
              localStorage.removeItem("activation");
              localStorage.removeItem("id");
              setTimeout((this.$router.push({ name: "Signin" }), 2500));
            }
          })
          .catch((err) => {
            swal({
              text: "Hatalı Kullanıcı Adı Veya Şifre",
              icon: "error",
              closeOnClickOutside: false,
            });
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        swal({
          text: "Şifreler Eşleşmiyor",
          icon: "error",
          closeOnClickOutside: false,
        });
      }
    },
  },
  mounted() {
    this.email = localStorage.getItem("email");
    this.activation = localStorage.getItem("activation");
    localStorage.removeItem("token");
    localStorage.removeItem("admin_token");
    localStorage.removeItem("key");
    localStorage.removeItem("admin_id");
    this.loading = false;
    this.password = null;
  },
};
</script>

<style>
.not-found {
  text-align: center;
}
.not-found h2 {
  font-size: 150px;
  margin-top: 30px;
}
.not-found h3 {
  font-size: 50px;
}
</style>
