<template>
  <div class="container">
    <div class="row pt-5">
      <div class="col-md-1"></div>
      <div class="col-md-4 col-12">
        <img :src="product.imageURL" :alt="product.name" class="img-fluid" />
      </div>
      <div class="col-md-6 col-12 pt-3 pt-md-0">
        <h4>{{ product.name }}</h4>
        <h6 class="category font-italic">{{ category.categoryName }}</h6>
        <h6 class="font-weight-bold">₺{{ product.price }}</h6>
        <p>
          {{ product.description }}
        </p>

        <div class="d-flex flex-row justify-content-between">
          <div class="input-group col-md-3 col-4 p-0">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"
                >Ürün Miktarı</span
              >
              <span class="input-group-text" type="number">{{ quantity }}</span>
            </div>

            <button
              
              class="buttonbackground btn btn-secondary"
              id="up"
              @click="setQuantity('up')"
            >
              +
            </button>
            &nbsp;&nbsp;
            <button
              
              class="buttonbackground btn btn-secondary "
              id="down"
              @click="setQuantity('down')"
            >
              -
            </button>
          </div>

          <div class="input-group col-md-3 col-4 p-0">
            <button
              type="button"
              id="add-to-cart-button"
              class="btn"
              @click="addToCart()"
            >
              Sepete Ekle
              <ion-icon name="cart-outline" v-pre></ion-icon>
            </button>
          </div>
        </div>

        <div class="features pt-3">
          <h5><strong>Ürün Açıklamaları</strong></h5>
          <ul>
            <li v-for="item in items" :key="item.message">{{ item }}</li>
            <!-- <li>{{ content2 }}</li>
            <li>{{ content3 }}</li>
            <li>{{ content4 }}</li>
            <li>{{ content5 }}</li> -->
          </ul>
        </div>

        <button
          id="wishlist-button"
          class="btn mr-3 p-1 py-0"
          :class="{ product_added_wishlist: isAddedToWishlist }"
          @click="addToWishList(this.id)"
        >
          {{ wishlistString }}
        </button>
        <hr />
        <div>
          <span style="font-weight:bold;font-style: oblique;"
            >Mağaza Adı :
          </span>
          <span style="font-style: italic;">{{ companyName }}</span>
        </div>
        <div>
          <span style="font-weight:bold;font-style: oblique;"
            >Mağaza Değerlendirmesi :
          </span>
          <span style="font-style: italic;">{{ companyValue }}</span>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: {},
      items: {},
      category: {},
      companyName: null,
      companyValue: null,
      id: null,
      token: localStorage.getItem("token"),
      isAddedToWishlist: false,
      wishlistString: "Favorilerime Ekle",
      quantity: 1,
    };
  },
  props: ["baseURL", "products", "categories"],
  methods: {
    addToWishList() {
      if (!this.token) {
        swal({
          text: "Favorilere Eklemek İçin Giriş Yapmanız Gerekiyor!",
          icon: "error",
        });
        return;
      }
      const wishlist = {
        token: this.token,
        product_id: this.id,
      };
      axios({
        method: "post",
        url:
          `${this.baseURL}Wishlist/createWishlist.php?key=123`,
        data: JSON.stringify(wishlist),
      }).then(
        (response) => {
          if (response.data == true) {
            this.isAddedToWishlist = true;
            this.wishlistString = "Favorilerinize Eklendi";
            swal({
              text: "Favorilerinize Eklendi!",
              icon: "success",
            });
          } else if (response.data == 2002) {
            this.isAddedToWishlist = true;
            this.wishlistString = "Favorilerinize Eklendi";
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },
    addToCart() {
      if (!this.token) {
        swal({
          text: "Sepete Eklemek İçin Giriş Yapmanız Gerekiyor!",
          icon: "error",
        });
        return;
      }
      const cart = {
        piece: this.quantity,
        token: this.token,
        product_id: this.id,
        admin_id: this.product.admin_id,
      };
      //console.log(this.product.admin_id)
      axios({
        method: "post",
        url:
          `${this.baseURL}Cart/createCart.php?key=123`,
        data: JSON.stringify(cart),
      }).then(
        (response) => {
          console.log(response.data);
          if (response.data == true) {
            swal({
              text: "Ürün Sepetinize Başarıyla Eklendi!",
              icon: "success",
              closeOnClickOutside: false,
            });
            // refresh nav bar
            this.$emit("fetchData");
          } else if (response.data == 5005) {
            this.alertDisplay();
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },

    alertDisplay() {
      swal({
        title: "Sepeti Boşaltmak İster Misiniz?",
        text:
          "Sepetinizdeki Ürünler Farklı Bir Mağazaya Ait. Aynı Mağazadan Ürünler Sepetinize Ekleyebilirsiniz",
        icon: "warning",
        buttons: ["Vazgeç", "Devam Et!"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Sepet Boşaltıldı Ve Ürün Sepetinize Eklendi", {
            icon: "success",
          });
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },

    listCartItems() {
      if (!this.token) {
        swal({
          text:
            "Oturum Açmadığınız İçin Sepetiniz Boş. Oturum Açınız Veya Hesabınız Yok İse Hesap Oluşturunuz.",
          icon: "error",
        });
        return;
      }
      axios.get(`${this.baseURL}cart/?token=${this.token}`).then(
        (response) => {
          if (response.status === 200) {
            this.$router.push("/cart");
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },
    setQuantity(upordown) {
      this.tempData = this.quantity;

      if (this.tempData > 1) {
        if (upordown == "up") {
          this.tempData += 1;
          this.quantity = this.tempData;
        } else if (upordown == "down") {
          this.tempData -= 1;
          this.quantity = this.tempData;
        }
      } else if (this.quantity == 1) {
        if (upordown == "up") {
          this.tempData += 1;
          this.quantity = this.tempData;
        }
      } else {
        this.quantity = 1;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.id = this.$route.params.id;
    console.log(this.products);
    this.product = this.products.find((product) => product.id == this.id);
    this.category = this.categories.find(
      (category) => category.id == this.product.categoryId
    );
    this.token = localStorage.getItem("token");
  },
  created: function() {
    // console.log("created");
    this.product_id = this.$route.params.id;
    const datas = {
      product_id: this.product_id,
    };
    axios({
      method: "post",
      url:
        `${this.baseURL}ProductContent/getByIdProductContent.php?key=123`,
      data: JSON.stringify(datas),
    })
      .then((response) => {
        this.items = response.data.data;
        this.companyName = response.data.admin.companyName;
        this.companyValue = response.data.admin.companyValue;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>

<style>
.category {
  font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#add-to-cart-button {
  background-color: #febd69;
}

#wishlist-button {
  background-color: #b9b9b9;
  border-radius: 0;
}

#show-cart-button {
  background-color: #131921;
  color: white;
  border-radius: 0;
}
.buttonbackground {
  background-color: rgb(254, 189, 105, 1);
  margin-top:5px;
}

</style>
