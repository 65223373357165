<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Yeni Ürün Ekle</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-md-6 px-5 px-md-0">
        <form>
          <div class="form-group">
            <label>Kategory</label>
            <select class="form-control" v-model="categoryId" required>
              <option v-for="category of categories" :key="category.id" :value="category.id">{{category.categoryName}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Ürün Adı</label>
            <input  placeholder="Örnek: Kayısı" type="text" class="form-control" v-model="name" required>
          </div>
          <div class="form-group">
            <label>Tanım</label>
            <input placeholder="Örnek:Yeni Sezon" type="text" class="form-control" v-model="description" required>
          </div>
          <div class="form-group">
            <label>Fotoğraf Url</label>
            <!-- buraya fotoğraf eklem modulu yapılcak -->
            <input placeholder="Fotoğraf Galerisinden Kopyalayınız" type="url" class="form-control" v-model="imageURL" required>
          </div>
          <div class="form-group">
            <label>Fiyatı</label>
            <input placeholder="Ürün Fiyatını TL Bazında Giriniz" type="number" class="form-control" v-model="price" required>
          </div>
          <button type="button" class="btn btn-primary" @click="addProduct">Kaydet</button>
        </form>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      id : null,
      categoryId : null,
      name : null,
      description : null,
      imageURL : null,
      price : null
    }
  },
  props : ["baseURL", "products", "categories"],
  methods : {
    async addProduct() {
      var token = localStorage.getItem('admin_token')
      var key = localStorage.getItem('key')
      console.log(token);
      const newProduct = {
        admin_token : token,
        key : key,
        categoryId : this.categoryId,
        name : this.name,
        description : this.description,
        imageURL : this.imageURL,
        price : this.price
      }
      await axios({
        method: 'post',
        url: `${this.baseURL}Product/createProduct.php?key=123`,
        data : JSON.stringify(newProduct),
      })
      .then(res => {
        //sending the event to parent to handle
        this.$emit("fetchData");
        swal({
          text: "Ürün Başarıyla Eklendi!",
          icon: "success",
          closeOnClickOutside: false,
        });
        setTimeout(() => {  this.$router.go(); }, 1500);
      })
      .catch(err => console.log(err));
    }
  },
  mounted() {
    if (!localStorage.getItem('admin_token')) {
      this.$router.push({name : 'Signin'});
    }
  }
}
</script>

<style scoped>
h4 {
  font-family: 'Roboto', sans-serif;
  color: #484848;
  font-weight: 700;
}

</style>
