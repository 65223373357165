<template>
  <div class="card-background card h-100 w-100">
    <div class="embed-responsive embed-responsive-16by9">
      <!-- embed-responsive-item   aşağı clasa eklersen fotoğrafı responsive kullanır -->
      <img
        class="embed-responsive-item"   
        :src="product.imageURL"
        alt="Product Image"
      />
    </div>
    <div class="card-body">
      <router-link :to="{ name: 'ShowDetails', params: { id: product.id } }"
        ><h5 class="card-title">{{ product.name }}</h5></router-link
      >
      <p class="card-text">{{ product.price }}<span>₺</span></p>
      <p class="card-text font-italic">
        {{ product.description.substring(0, 65) }}...
      </p>
      <router-link
        style ="padding-right: 5px;padding-left: 5px"
        id="edit-product"
        :to="{ name: 'EditProduct', params: { id: product.id } }"
        v-show="$route.name == 'AdminProduct'"
      >
        Düzenle
      </router-link>

      <router-link
        style ="padding-right: 5px;padding-left: 5px"
        id="edit-product"
        :to="{ name: 'ContentProduct', params: { id: product.id } }"
        v-show="$route.name == 'AdminProduct'"
      >
        Açıklama
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      token: null,
      quantity: 1,
    };
  },
  name: "ProductBox",
  props: ["product", "baseURL"],
  methods: {
    showDetails() {
      this.$router.push({
        name: "ShowDetails",
        params: { id: this.product.id },
      });
    },
  },
  mounted() {
    this.token = localStorage.getItem("token");
  },
};
</script>

<style scoped>
.embed-responsive{
color: white;
}

a {
  text-decoration: none;
}

.card-title {
  color: #484848;
  font-size: 1.1rem;
  font-weight: 400;
}

.card-title:hover {
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
}

#edit-product {
  float: right;
}
.card-background {
    background-color: rgb(199 209 235 / 20%)!important
}
</style>
