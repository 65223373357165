<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Sipariş Numarası: {{ orderID }}</h4>
      </div>
    </div>

    <div
      v-for="(orderItem, index) in orderItems"
      :key="index"
      class="row mt-2 pt-3 justify-content-around"
    >
      <div class="col-1"></div>
      <div class="col-md-3 embed-responsive embed-responsive-16by9">
        <img
          :src="orderItem.imageURL"
          class="w-100 card-img-top embed-responsive-item"
        />
        <hr />
      </div>

      <div class="col-md-5 px-3">
        <div class="card-block px-3">
          <h6 class="card-title">{{ orderItem.name }}</h6>
          <p id="item-price" class="mb-0 font-weight-bold">
            Adet Fiyatı {{ orderItem.price }} ₺
          </p>
          <p id="item-quantity" class="mb-0">
            Sipariş Adeti : {{ orderItem.piece }}
          </p>
          <p id="item-total-price" class="mb-0">
            Toplam Tutar :<span class="font-weight-bold"
              >{{ orderItem.piece * orderItem.price }} ₺</span
            >
          </p>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <hr />
    <div class="total-cost pt-2 text-center">
      <h5>
        Sipariş Anındaki Toplam Sipariş Tutarı : {{ dataItems.totalCost }} ₺
      </h5>
      <h5>Sipariş Tarihi : {{ dataItems.reg_date }}</h5>
      <h5>Sipariş Durumu : {{ dataItems.orderStatus }}</h5>
      <h5>{{ cargoInfo }}</h5>
    </div>
    <hr />
    <div class="text-center">
      <button
        @click="confirmOrderAdmin"
        class="btn btn-outline-success mr-1"
        href="#"
      >
        Siparişi Onayla
      </button>
      <button
        @click="showCargoInfo"
        class="btn btn-outline-warning mr-1"
        href="#"
      >
        Müşteri Kargo Ve İletişim Bilgileri
      </button>
      <button
        @click="deliveryInfo"
        class="btn btn-outline-secondary mr-1"
        href="#"
      >
        Teslimat Bilgileri
      </button>
      <button @click="deliveryOrder" class="btn btn-outline-info mr-1" href="#">
        Siparişi Sonlandır
      </button>
      <button @click="deleteOrder" class="btn btn-outline-danger " href="#">
        Siparişi İptal Et
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderItems",
  props: ["orderID", "baseURL"],

  data() {
    return {
      orderItems: [],
      dataItems: [],
      order: {},
      token: null,
      orderID: 0,
      
    };
  },

  methods: {
    getOrder() {
      const cart = {
        admin_token: this.admin_token,
        orderNo: this.orderID,
        key: this.key,
      };
      axios({
        method: "post",
        url: `${this.baseURL}Order/getAllOrderDetailAdmin.php?key=123`,
        data: JSON.stringify(cart),
      }).then(
        (response) => {
          if (response.status === 200) {
            // console.log()
            // this.order = response.data
            this.dataItems = response.data;
            this.orderItems;
            for (let i = 0; i < this.dataItems.totalItems; i++) {
              // console.log(this.orderItems[0])
              this.orderItems[i] = this.dataItems[i];
            }
            // console.log(this.dataItems);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    confirmOrderAdmin() {
      swal({
        title: "Siparişi Onaylamak İstiyor Musunuz?",
        text:
          "Siparişi Onayladıktan Sonra 2 Gün İçinde Sağlam Bir Paket Şeklinde Kargoya Vermeniz Önerilmektedir. Eksik Ürün Veya Kusurlu Ürün Gönderiminde Kargo Size Aittir.",
        icon: "info",
        buttons: ["Vazgeç", "Devam Et!"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const cart = {
            admin_token: this.admin_token,
            orderNo: this.orderID,
            //key: this.key,
          };
          axios({
            method: "post",
            url: `${this.baseURL}Order/confirmOrderByAdmin.php?key=123`,
            data: JSON.stringify(cart),
          }).then(
            (response) => {
              if (response.data == true) {
                swal(
                  "Siparişi Onayladınız. Siparişi Kargoya Verdikten Sonra Kargo Bilgileri Kısmını Doldurunuz",
                  {
                    icon: "success",
                  }
                );
                setTimeout(() => {
                  this.$router.go();
                }, 2000);
              } else {
                swal(
                  "Sipariş Onaylanırken Bir Hata Oluştu Lütfen Çıkış Yapıp Tekrar Deneyiniz!",
                  {
                    icon: "error",
                  }
                );
                // setTimeout(() => {
                //   this.$router.go();
                // }, 2000);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },
    deliveryOrder() {
      swal({
        title: "Siparişi Tamamlamak İstiyor Musunuz?",
        text:
          "Sipariş Kargo Firması Tarafından Teslim Edildikten Sonra Onay İşlemini Gerçekleştiriniz. Ürün Teslim Edilmeden Bu İşlemi Onaylarsanız Oluşabilecek Problemler Mağaza Puanınızı Düşürebilir. Düşük Mağaza Puanı Müşteri Kaybına Yol Açabilir!!",
        icon: "info",
        buttons: ["Vazgeç", "Siparişi Tamamla"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const cart = {
            admin_token: this.admin_token,
            orderNo: this.orderID,
            //key: this.key,
          };
          axios({
            method: "post",
            url: `${this.baseURL}Order/deliveryOrder.php?key=123`,
            data: JSON.stringify(cart),
          }).then(
            (response) => {
              if(response.data == true)
      swal({
                text:
                  "İşlem Başarıyla Gerçekleştirildi",
                icon: "success",
                closeOnClickOutside: false,
              });
                              setTimeout(() => {
                  this.$router.push("/admin/adminOrder");
                }, 1000);
            },
         
          );
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },
    deliveryInfo() {
      swal({
        title: "Kargo Bilgilerini Girmek İstiyor Musunuz?",
        text:
          "Ürünleri Kargoya Verdikten Sonra Kargo Firması Tarafından Size İletilen Kargo Takip Bilgilerini Sisteme Girmeniz Gerekmektedir. Kargo Bilgilerini Başarılı Bir Şekilde Girdikten Sonra Siparişiniz 'Kargoya Verildi' Durumunda Görünecektir. Bilgileri Eksiksiz Ve Doğru Girdiğinizden Emin Olunuz!",
        icon: "info",
        buttons: ["Vazgeç", "Devam Et"],
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          this.$router.push({ name: 'OrderDeliveryInfo' , params: {id: this.orderID}});
    
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },
    showCargoInfo() {
      swal({
        title: "Kargo Bilgilerini Görüntülemek İstiyor Musunuz?",
        text:
          "Kargo Bilgileri İçin Sağlanan Bilgilerin Yayılması Veya Kötü Amaçlı Kullanımı Tespit Edilirse Cezai İşlem Uygulanacaktır!",
        icon: "info",
        buttons: ["Vazgeç", "Görüntüle"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const cart = {
            admin_token: this.admin_token,
            orderNo: this.orderID,
            //key: this.key,
          };
          axios({
            method: "post",
            url: `${this.baseURL}Order/getByUserCargoInfo.php?key=123`,
            data: JSON.stringify(cart),
          }).then(
            (response) => {
              //console.log(response);
              if (response.data.code == 2005) {
                swal(
                  "Ad Soyad: " +
                    response.data.nameSurname +
                    "\n Telefon: " +
                    response.data.telephone +
                    "\n Adres: " +
                    response.data.fullAdress +
                    "\n İl-İlçe: " +
                    response.data.cityTown,
                  {
                    icon: "warning",
                  }
                );
                //setTimeout(() => {  this.$router.go(); }, 1000);
              } else {
                swal(
                  "Kargo Bilgilerinde Bir Hata Oluştu. \n Çıkış Yapıp Tekrar Deneyiniz Düzelmemesi Durumunda Müşteri Hizmetlerine Ulaşınız.",
                  {
                    icon: "error",
                  }
                );
                setTimeout(() => {
                  this.$router.go();
                }, 4000);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },
    deleteOrder() {
      swal({
        title: "Siparişi Silmek İstediğinize Emin Misiniz?",
        text:
          "Siparişi İptal Etmek Üzerisiniz. Siparişi İptal Etmeniz Durumunda Bakiyenizden Kesinti Ücreti Tahsil Edilecektir. Devam Etmek İçin İptal Et Butonuna Basınız!",
        icon: "info",
        buttons: ["Vazgeç", "Siparişi Sil"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const cart = {
            admin_token: this.admin_token,
            orderNo: this.orderID,
            //key: this.key,
          };
          axios({
            method: "post",
            url: `${this.baseURL}Order/deleteOrderByAdmin.php?key=123`,
            data: JSON.stringify(cart),
          }).then(
            (response) => {
              //console.log(response);
              if (response.data == 1) {
                swal("Sipariş Başarıyla İptal Edildi.", {
                  icon: "success",
                });
                setTimeout(() => {
                  this.$router.push("/admin/adminOrder");
                }, 1000);
              } else {
                swal(
                  "Sipariş İptal Edilirken Bir Hata Oluştu. \n Çıkış Yapıp Tekrar Deneyiniz Düzelmemesi Durumunda Müşteri Hizmetlerine Ulaşınız.",
                  {
                    icon: "error",
                  }
                );
                setTimeout(() => {
                  this.$router.go();
                }, 4000);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } //else {
        //   swal("Your imaginary file is safe!");
        // }
      });
    },
  },

  mounted() {
    this.orderID = this.$route.params.id;
    this.admin_token = localStorage.getItem("admin_token");
    this.key = localStorage.getItem("key");
    this.getOrder();
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

.embed-responsive .card-img-top {
  object-fit: cover;
}
</style>
