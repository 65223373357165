<template>
  <div class="container">
    <!--    Logo Div-->


    <div class="row">
      <div class="col-12 justify-content-center d-flex flex-row pt-5">
        <div id="signin-div" class="flex-item border">
              <div class="row">
      <div class="col-12 text-center pt-3">
        <router-link :to="{ name: 'Home' }">
      <img style="max-height:20px;max-width:20px" src="../assets/apricot.svg" />

      <img id="logo" src="../assets/1sepet.png" />
        </router-link>
      </div>
    </div>
          <h2 style="color:white" class="pt-4 pl-4">Giriş Yap</h2>
          <form @submit="signin" class="pt-4 pl-4 pr-4">
            <div class="form-group">
              <label>Email Adresiniz</label>
              <input placeholder="Kayıt Esnasında Kullandığınız Email Adresinizi Giriniz" type="email" class="form-control" v-model="email" required />
            </div>
            <div class="form-group">
              <label>Şifrenizi Giriniz</label>
              <input placeholder="Şifrenizi Giriniz" type="password" class="form-control" v-model="password" required />
            </div>
            <small class="form-text text-muted">Devam ederek 1SepetKayısı'nın Kullanım Koşullarını ve Gizlilik
              Bildirimini kabul etmiş olursunuz.</small>
            <div style="text-align:center!important;">
              <button type="submit" class="btn btn-primary mt-2 py-0" style="text-align : center;border-radius: 4px !important;">
                Devam Et
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Yükleniyor...</span>
                </div>
              </button>
              <br>
              <br>
              <router-link style="font-size: 12px" :to="{ name: 'ForgetPassword' }" class="btn btn-info">Şifremi Unuttum</router-link>
            </div>
          </form>
          <hr />
          <small class="form-text text-muted pt-2 pl-4 text-center">1SepetKayısıda yeni misiniz?</small>
          <p class="text-center">
            <router-link :to="{ name: 'Signup' }" class="btn btn-light text-center mx-auto px-5 py-1 mb-2">Yeni Hesap
              Oluştur</router-link>
              <br>
            <router-link :to="{ name: 'AdminAppeal' }" class="btn btn-secondary text-center mx-auto px-5 py-1 mb-2">Bizimle Çalışmak İster Misiniz?</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signin",
  props: ["baseURL"],
  data() {
    return {
      email: null,
      password: null,
      loading: null,
    };
  },
  methods: {
    async signin(e) {
      e.preventDefault();
      this.loading = true;

      const user = {
        email: this.email,
        password: this.password,
      };

      await axios({
        method: 'post',
        url: `${this.baseURL}Login/signin.php?key=123`,
        data: JSON.stringify(user),
      })
        .then((res) => {

          if (res.data.token === null || res.data.token === undefined) {
            reload();
          } else {
            if (res.data.access == "user") {
              localStorage.setItem("token", res.data.token);
              this.$emit("fetchData");
              this.$router.push({ name: "Home" });
              
            } else if (res.data.access == "admin"){
              localStorage.setItem("admin_token", res.data.token);
              localStorage.setItem("key", res.data.key);
              this.$emit("fetchData");
              this.$router.push({ name: "Admin" });
            }
          }

        })
        .catch((err) => {
          swal({
            text: "Hatalı Kullanıcı Adı Veya Şifre",
            icon: "error",
            closeOnClickOutside: false,
          });
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("admin_token");
    localStorage.removeItem("key");
    localStorage.removeItem("admin_id");
    this.loading = false;
    this.password = null;
  },
};
</script>

<style scoped>
.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signin-div {
    width: 40%;
  }
}
</style>
