<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="pt-3"><span style="color:#b58c1b">ÜRÜNLER</span></h3>
        <h6 class="pt-3"><span style="color:#b58c1b">{{ product_size }} Adet ürün Bulundu</span></h6>
      </div>
    </div>
    <div class="row">
      <div
        v-for="product of products"
        :key="product.id"
        class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
      >
        <ProductBox :product="product"> </ProductBox>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../../components/Product/ProductBox";
export default {
  data() {
    return {
      product_size : 0,
      product: [],
      productList: [],
    };
  },
  name: "Product",
  components: { ProductBox },
  props: ["baseURL", "products"],
  mounted() {
      this.product_size = this.products.length;
      this.product = this.products;
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

#add-product {
  float: right;
  font-weight: 500;
}
</style>
