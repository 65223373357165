import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Admin from '../views/Admin/Admin.vue'
import Gallery from '../views/Admin/Gallery.vue'
import AdminOrder from '../views/Admin/AdminOrder.vue'
import OrderDeliveryInfo from '../views/Admin/OrderDeliveryInfo.vue'
import AdminOrderDetail from '../views/Admin/AdminOrderDetail.vue'
import AddImage from '../views/Admin/AddImage.vue'

import PageNotFound from '../views/PageNotFound.vue'

import Product from '../views/Product/Product.vue'
import Showproduct from '../views/Product/Showproduct.vue'
import AddProduct from '../views/Product/AddProduct.vue'
import EditProduct from '../views/Product/EditProduct.vue'
import ContentProduct from '../views/Product/ContentProduct.vue'
import ShowDetails from '../views/Product/ShowDetails.vue'
import Wishlist from '../views/Product/Wishlist.vue'
import HangerProduct from '../views/Product/HangerProduct.vue'
import Cart from '../views/Cart/Cart.vue'
import Checkout from '../views/Checkout/Checkout.vue'
import Order from '../views/Orders/Order.vue'
import PassOrder from '../views/Orders/PassOrder.vue'

import Category from '../views/Category/Category.vue'
import AddCategory from '../views/Category/AddCategory.vue'
import EditCategory from '../views/Category/EditCategory.vue'
import ListProducts from '../views/Category/ListProducts.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import ForgetPasswordActivation from '../views/ForgetPasswordActivation.vue'
import PasswordCheck from '../views/PasswordCheck.vue'
import MyProfile from '../views/MyProfile.vue'
import AdminAppeal from '../views/AdminAppeal.vue'

import Success from '../helper/payment/Success.vue'
import Failed from '../helper/payment/Failed.vue'

import OrderDetails from "../views/Orders/OrderDetails";
import PassOrderDetails from "../views/Orders/PassOrderDetails";

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    //Admin routes
    {
        path: '/admin',
        name: 'Admin',
        component: Admin
    },
    {
        path: '/admin/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/admin/gallery/add',
        name: 'AddImage',
        component: AddImage
    },
    {
        path: '/admin/adminOrder',
        name: 'AdminOrder',
        component: AdminOrder
    },
    {
        path: '/admin/oderDeliveryInfo/:id',
        name: 'OrderDeliveryInfo',
        component: OrderDeliveryInfo
    },
    {
        path: '/admin/adminOrderDetail/:id',
        name: 'AdminOrderDetail',
        component: AdminOrderDetail
    },
    //Product routes
    {
        path: '/product',
        name: 'Product',
        component: Product
    },
    {
        path: '/showproduct',
        name: 'Showproduct',
        component: Showproduct
    },
    {
        path: '/admin/product',
        name: 'AdminProduct',
        component: Product
    },
    {
        path: '/admin/hanger/',
        name: 'HangerProduct',
        component: HangerProduct
    },
    {
        path: '/admin/product/add',
        name: 'AddProduct',
        component: AddProduct
    },
    {
        path: '/admin/product/:id',
        name: 'EditProduct',
        component: EditProduct,
    },
    {
        path: '/admin/content/:id',
        name: 'ContentProduct',
        component: ContentProduct,
    },
    {
        path: '/product/show/:id',
        name: 'ShowDetails',
        component: ShowDetails
    },
    //Category routes
    {
        path: '/category',
        name: 'Category',
        component: Category
    },
    {
        path: '/admin/category',
        name: 'AdminCategory',
        component: Category
    },
    {
        path: '/admin/category/add',
        name: 'AddCategory',
        component: AddCategory
    },
    {
        path: '/admin/category/:id',
        name: 'EditCategory',
        component: EditCategory
    },
    {
        path: '/category/show/:id',
        name: 'ListProducts',
        component: ListProducts
    },
    //Page Not found
    {
        path: '/:catchAll(.*)',
        name: 'PageNotFound',
        component: PageNotFound
    },
    //Signin and Signup
    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    },
    {
        path: '/signin',
        name: 'Signin',
        component: Signin
    },
    {
        path: '/forgetpassword',
        name: 'ForgetPassword',
        component: ForgetPassword
    },
    {
        path: '/myprofile',
        name: 'MyProfile',
        component: MyProfile
    },
    {
        path: '/passwordCheck',
        name: 'PasswordCheck',
        component: PasswordCheck
    },
    {
        path: '/adminappeal',
        name: 'AdminAppeal',
        component: AdminAppeal
    },
    {
        path: '/forgetpasswordactivation',
        name: 'ForgetPasswordActivation',
        component: ForgetPasswordActivation
    },
    {
        path: '/wishlist',
        name: 'Wishlist',
        component: Wishlist
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    {
        path: '/order',
        name: 'Order',
        component: Order
    },
    {
        path: '/passOrder',
        name: 'PassOrder',
        component: PassOrder
    },
    {
        path: '/payment/success',
        name: 'PaymentSuccess',
        component: Success
    },
    {
        path: '/payment/failed',
        name: 'FailedPayment',
        component: Failed
    },
    {
        path: '/passOrder/:id',
        name: 'PassOrderDetails',
        component: PassOrderDetails
    },
    {
        path: '/order/:id',
        name: 'OrderDetails',
        component: OrderDetails
    }
]

// const router = new VueRouter({
//     routes: routes,
//     mode: "history",
//     scrollBehavior(to, from, savedPosition) {
//         if (to.hash) {
//             return {
//                 selector: to.hash
//             };
//         }
//         return { x: 0, y: 0 }
//     }

// });

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

})

//scroll to top after every route change
// router.beforeEach((to, from, next) => {
//     try {
//         var deneme2 = localStorage.getItem("deneme");
//         console.log(localStorage.getItem("deneme"));
//         window.scrollTo(0, 0);
//         next();

//     } catch (err) {
//         console.log(err.message);
//     }

// });

export default router