<template>
  <div class="container">
    <div class="row">
      <div class="col-12 justify-content-center d-flex flex-row pt-5">
        <div id="signup-div" class="flex-item border">
          <h2 class="pt-4 pl-4">Hesap Bilgilerim</h2>
          <div class="pt-4 pl-4 pr-4">
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                v-model="email"
                required
              />
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label>Adınız:</label>
                  <input
                    type="name"
                    class="form-control"
                    v-model="this.name"
                    required
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Soyadınız:</label>
                  <input
                    type="name"
                    class="form-control"
                    v-model="surname"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label>Şehir:</label>
                  <!-- şehir -->
                  <input
                    type="name"
                    class="form-control"
                    v-model="city"
                    required
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>İlçe:</label>
                  <!-- ilçe -->
                  <input
                    type="name"
                    class="form-control"
                    v-model="town"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Açık Adres</label>
              <!-- Açık Adres -->
              <input
                type="name"
                class="form-control"
                v-model="adress"
                required
              />
            </div>
            <div class="form-group">
              <label>Telefon Numarası</label>
              <!-- Telefon Numarası -->
              <input
                class="form-control"
                v-model="telephone"
                type="telephone"
                @input="acceptNumber"
              />
            </div>
            <div style="text-align: center">
            <button @click="updateProfile" type="button" class="btn btn-info">Güncelle</button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      product: null,
      token: "",
      result: [],
      name: "",
      surname: "",
      telephone: "",
      email: "",
      adress: "",
      city: "",
      town: "",
    };
  },
  methods: {
    acceptNumber() {
      var x = this.telephone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.telephone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async updateProfile() {

        const user = {
          email: this.email,
          name: this.name,
          surname: this.surname,
          city: this.city,
          town: this.town,
          adress: this.adress,
          telephone: this.telephone,
          token: this.token,
        };

        // call the API
        await axios({
          method: "post",
          url:
             `${this.baseURL}User/updateByMyIdUser.php?key=123`,
          data: JSON.stringify(user),
        })
          .then((response) => {
              if(response.data == true){
        swal({
              text:
                "Başarıyla Güncellendi",
              icon: "success",
              closeOnClickOutside: false,
            });
              }else{
                          swal({
              text:
                "Bir Hata Oluştu",
              icon: "error",
              closeOnClickOutside: false,
            });
              }
    
          })
          .catch((err) => {
            console.log(err);
          });

    },
    editProduct() {
      const datas = {
        token: this.token,
      };

      axios({
        method: "post",
        url:
          `${this.baseURL}User/getByIdUser.php?key=123`,
        data: JSON.stringify(datas),
      })
        .then((response) => {
          //console.log(response);
          datas = response.data;

          this.name = datas.name;
          console.log(response);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Signin" });
      return;
    }
    this.token = localStorage.getItem("token");
  },
  created: function() {
    this.token = localStorage.getItem("token");
    const datas = {
      token: this.token,
    };

    axios({
      method: "post",
      url:
        "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/User/getByIdUser.php?key=123",
      data: JSON.stringify(datas),
    })
      .then((response) => {
        //console.log(response);
        console.log(response.data);

        this.name = response.data.name;
        this.surname = response.data.surname;
        this.telephone = response.data.telephone;
        this.email = response.data.email;
        this.adress = response.data.adress;
        this.city = response.data.city;
        this.town = response.data.town;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>
<style>
.not-found {
  text-align: center;
}
.not-found h2 {
  font-size: 150px;
  margin-top: 30px;
}
.not-found h3 {
  font-size: 50px;
}
</style>
