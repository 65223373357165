<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="pt-3">Alışveriş Sepetim</h3>
      </div>
    </div>
    <!--    loop over all the cart items and display one by one-->
    <div
      v-for="cartItem in cartItems"
      :key="cartItem.id"
      class="row mt-2 pt-3 justify-content-around"
    >
      <div class="col-2"></div>
      <!-- display image -->
      <div class="col-md-3 embed-responsive embed-responsive-16by9">
        
        <router-link
          :to="{ name: 'ShowDetails', params: { id: cartItem.id } }"
        >
          <img
            v-bind:src="cartItem.imageURL"
            class="w-100 card-img-top embed-responsive-item"
          />
        </router-link>
      </div>
      <!-- display product name, quantity and price -->
      <div class="col-md-5 px-3">
        <div class="card-block px-3">
          <h6 class="card-title">
            <router-link
              :to="{ name: 'ShowDetails', params: { id: cartItem.id } }"
              >{{ cartItem.name }}
            </router-link>
          </h6>
          <p id="item-price" class="mb-0 font-weight-bold">
            Adet Fiyatı {{ cartItem.price }}₺
          </p>
          <p id="item-quantity" class="mb-0">
            Ürün Adeti :
            <span class="font-weight-bold">
             {{ cartItem.piece }}</span
            >
          </p>
          <p id="item-total-price" class="mb-0">
            Toplam Tutar:
            <span class="font-weight-bold">
              ₺ {{ cartItem.price * cartItem.piece }}</span
            >
          </p>
          <br /><a href="#" class="text-right" @click="deleteItem(cartItem.id)"
            >Ürünü Kaldır</a
          >
        </div>
      </div>
      <div class="col-2"></div>
      <div class="col-12"><hr /></div>
    </div>

    <!-- display total price -->
    <div class="total-cost pt-2 text-right">
      <h5>Toplam Tutar : ₺{{ totalcost.toFixed(2) }}</h5>
      <button
        :disabled="isDisabled()"
        type="button"
        class="btn btn-primary confirm"
        @click="checkout"
      >
        Siparişi Onayla
      </button>
      <button @click="denemeodeme">Ödemesiz Deneme</button>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
export default {
  data() {
    return {
      cartItems: [],
      token: null,
      totalcost: 0,
    };
  },
  name: 'Cart',
  props: ['baseURL'],
  methods: {
    isDisabled() {
      if (this.cartItems.length === 0) {
        return true;
      }
      return false;
    },
    
    // fetch all the items in cart
    listCartItems() {
      const cart = {
        token: this.token,
      };
      axios({
        method: 'post',
        url: `${this.baseURL}Cart/getAllCart.php?key=123`,
        data: JSON.stringify(cart),
      })
        .then(
        (response) => {
          if(response.data == 2000){
            swal({
              text: "Sepetinizde Ürün Bulunmamaktadır!",
              icon: "error",
              closeOnClickOutside: false,
          });
          }
          if (response.status == 200) {
            const result = response.data;
            this.cartItems = result.cart;
            this.totalcost = result.totalCost;
           // localStorage.setItem("data", result.data);
          }
          if (response.data == 1001) {
   swal({
          text: "Sepetinizi Görüntülemek İçin Giriş Yapmanız Gerekiyor!",
          icon: "error",
        });
                    setTimeout(() => {
                 this.$router.push({ name: 'Home'});
                }, 500);
          }

  

        },
        (error) => {
          console.log(error);
        }
      );
    },
    denemeodeme() {
      const cart = {
        token: this.token,
      };
      axios({
        method: 'post',
        url: "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Order/createOrder.php?key=123",
        data: JSON.stringify(cart),
      })
        .then(
        (response) => {
          if(response.data == 2000){
            swal({
              text: "Sepetinizde Ürün Bulunmamaktadır!",
              icon: "error",
              closeOnClickOutside: false,
          });
          }
          if (response.status == 200) {
            const result = response.data;
            this.cartItems = result.cart;
            this.totalcost = result.totalCost;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // go to checkout page
    checkout() {
      this.$router.push({ name: 'Checkout' });
    },
    // https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Cart/deleteCartProduct.php?key=123
    deleteItem(itemId) {
      const productdelete = {
        token: this.token,
        product_id : itemId,
      };
      axios({
        method: 'post',
        url: "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Cart/deleteCartProduct.php?key=123",
        data: JSON.stringify(productdelete),
      })
        .then(
          (response) => {
            if (response.data == 1) {
              swal({
                text: "Ürün Başarıyla Silindi!",
                icon: "success",
                closeOnClickOutside: false,
          });
          setTimeout(() => this.$router.go(), 500);
              
            }
            this.$emit('fetchData');
          },
          (error) => {
            console.log(error);
          }
        );
    },
    showDetails(productId) {
      this.$router.push({
        name: 'ShowDetails',
        params: { id: productId },
      });
    },
  },
  mounted() {
    this.token = localStorage.getItem('token');
    this.listCartItems();
  },
};
</script>

<style scoped>
h4,
h5 {
  font-family: 'Roboto', sans-serif;
  color: #484848;
  font-weight: 700;
}

.embed-responsive .card-img-top {
  object-fit: cover;
}

#item-price {
  color: #232f3e;
}

#item-quantity {
  float: left;
}

#item-total-price {
  float: right;
}

.confirm {
  font-weight: bold;
  font-size: larger;
}
</style>
