<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3"><span style="color:#b58c1b">FAVORİLERİNİZ</span></h4>
      </div>
    </div>

    <div class="row">
      <div
        v-for="product of products"
        :key="product.id"
        class="col-md-6 col-xl-4 col-12 pt-3  justify-content-around d-flex"
      >
        <div class="card-background card h-100 w-100">
          <div class="embed-responsive embed-responsive-16by9">
            <!-- embed-responsive-item   aşağı clasa eklersen fotoğrafı responsive kullanır -->
            <img
              class="embed-responsive-item"
              :src="product.imageURL"
              alt="Product Image"
            />
          </div>
          <div class="card-body">
            <router-link
              :to="{ name: 'ShowDetails', params: { id: product.id } }"
              ><h5 class="card-title">{{ product.name }}</h5></router-link
            >
            <p class="card-text">{{ product.price }}<span>₺</span></p>
            <p class="card-text font-italic">
              {{ product.description.substring(0, 65) }}...
            </p>
          </div>
          <button type="button" class="btn btn-dark" @click="deleteWishlist(product)">
            Favorilerden Kaldır
          </button>

          <button type="button" class="btn btn-light" @click="addToCart(product)">
            Sepete Ekle
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from "../../components/Product/ProductBox";
export default {
  data() {
    return {
      products: null,
      token: null,
    };
  },
  name: "Product",
  components: { ProductBox },
  props: ["baseURL"],
  methods: {
    addToCart(product) {
      if (!this.token) {
        swal({
          text: "Sepete Eklemek İçin Giriş Yapmanız Gerekiyor!",
          icon: "error",
        });
        return;
      }
      const cart = {
        piece: 1,
        token: this.token,
        product_id: product.id,
      };

      axios({
        method: "post",
        url:
          `${this.baseURL}Cart/createCart.php?key=123`,
        data: JSON.stringify(cart),
      }).then(
        (response) => {
          if (response.status == 200) {
            swal({
              text: "Ürün Sepetinize Başarıyla Eklendi!",
              icon: "success",
              closeOnClickOutside: false,
            });
            // refresh nav bar
            this.$emit("fetchData");
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },
    deleteWishlist(product) {
      if (!this.token) {
        swal({
          text: "Favorilerden Kaldırmak İçin Giriş Yapmanız Gerekiyor!",
          icon: "error",
        });
        return;
      }
      const deleteWishlist = {
        token: this.token,
        product_id: product.id,
      };

      axios({
        method: "post",
        url:
          `${this.baseURL}Wishlist/deleteWishlist.php?key=123`,
        data: JSON.stringify(deleteWishlist),
      }).then(
        (response) => {
          if (response.data == true) {
            swal({
              text: "Ürün Başarıyla Kaldırıldı!",
              icon: "success",
              closeOnClickOutside: false,
            });
            setTimeout(() =>this.$router.go(0),500)
            // refresh nav bar
            //this.$emit("fetchData");
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },
    fetchWishlist() {
      const wishlist = {
        token: this.token,
      };
      axios({
        method: "post",
        url:
          `${this.baseURL}Wishlist/getAllWishlist.php?key=123`,
        data: JSON.stringify(wishlist),
      }).then(
        (response) => {
          //console.log(response);
          if (response.status == 200 && response.data != null) {
            this.products = response.data;
          }
          if(response.data == "2000"){
      swal({
        title: "Ürünleri Görüntülemek İster Misiniz?",
        text:
          "Ürün Detaylarında Favorilere Ekle Butonunu Kullanarak Favorilerinize Ekleyebilirsiniz",
        icon: "info",
        buttons: ["Vazgeç", "Devam Et"],
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
               this.$router.push({ name: "Showproduct" })
              } 
            },
            (err) => {
              console.log(err);
            }
          );
          }else if(response.data == false){
      swal({
        title: "Giriş Yapmak İster Misiniz?",
        text:
          "Siparişleri Görüntülemek İçin Önce Giriş Yapmalısınız",
        icon: "info",
        buttons: ["Vazgeç", "Giriş Yap"],
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
               this.$router.push({ name: "Signin" })
              } 
            },
            (err) => {
              console.log(err);
            }
          );
          }
        },
        (error) => {
          console.log("error");
        }
      );
    },
  },
  mounted() {
    
    this.token = localStorage.getItem("token");
    this.fetchWishlist();
  },
};
</script>
<style scoped>
.embed-responsive {
  color: white;
}

a {
  text-decoration: none;
}

.card-title {
  color: #484848;
  font-size: 1.1rem;
  font-weight: 400;
}

.card-title:hover {
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
}

#edit-product {
  float: right;
}
.card-background {
    background-color: rgb(199 209 235 / 20%)!important
}
</style>
