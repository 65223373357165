<template>
  <div class="container">
    <!--    Logo Div-->
    <div class="row">
      <div class="col-12 text-center pt-3">
        <router-link :to="{ name: 'Home' }">
          <img id="logo" src="../assets/icon2.png" />
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 justify-content-center d-flex flex-row pt-5">
        <div id="signup-div" class="flex-item border">
          <h2 class="pt-4 pl-4">Hesap Oluştur</h2>
          <form @submit="signup" class="pt-4 pl-4 pr-4">
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" v-model="email" required />
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label>Adınız:</label>
                  <input type="name" class="form-control" v-model="name" required />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Soyadınız:</label>
                  <input type="name" class="form-control" v-model="surname" required />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label>Şehir:</label>
                  <!-- şehir -->
                  <input type="name" class="form-control" v-model="city" required />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>İlçe:</label>
                  <!-- ilçe -->
                  <input type="name" class="form-control" v-model="town" required />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Açık Adres</label>
              <!-- Açık Adres -->
              <input type="name" class="form-control" v-model="adress" required />
            </div>
            <div class="form-group">
              <label>Telefon Numarası</label>
              <!-- Telefon Numarası -->
              <input class="form-control" v-model="telephone" type="telephone" @input="acceptNumber" />
            </div>
            <div class="form-group">
              <label>Şifre Giriniz:</label>
              <input type="password" class="form-control" v-model="password" required />
            </div>
            <div class="form-group">
              <label>Şifrenizi Onaylayınız:</label>
              <input type="password" class="form-control" v-model="passwordConfirm" required />
            </div>
            <div style="text-align: center">
              <button type="submit" class="btn btn-primary mt-2 py-0">
                Hesap Oluştur
              </button>
            </div>
          </form>
          <hr />
          <small class="form-text text-muted pt-2 pl-4 text-center">Hesabınız Var Mı?</small>
          <p class="text-center">
            <router-link class="btn btn-dark text-center mx-auto px-5 py-1 mb-2" :to="{ name: 'Signin' }">Giriş Yap
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signup",
  props: ["baseURL"],
  data() {
    return {
      email: null,
      name: null,
      surname: null,
      city: null,
      town: null,
      adress: null,
      telephone: null,
      password: null,
      passwordConfirm: null,
    };
  },
  methods: {
    acceptNumber() {
      var x = this.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async signup(e) {
      e.preventDefault();
      // if the password matches
      if (this.password === this.passwordConfirm) {
        // make the post body
        const user = {
          email: this.email,
          name: this.name,
          surname: this.surname,
          city: this.city,
          town: this.town,
          adress: this.adress,
          telephone: this.telephone,
          password: this.password,
        };

        // call the API
        await axios({
          method: 'post',
          url: `${this.baseURL}User/createUser.php?key=`,
          data: JSON.stringify(user),
        })
          .then(() => {
            // redirect to home page
            this.$router.replace("/");
            swal({
              text: "Kayıt İşleminiz Başarıyla Tamamlandı. Lütfen Giriş Yapınız",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // passwords are not matching
        swal({
          text: "Hata! Parolalar eşleşmiyor. Tekrar Deneyiniz.",
          icon: "error",
          closeOnClickOutside: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signup-div {
    width: 40%;
  }
}
</style>
