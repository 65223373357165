<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="pt-3">Ürün Düzenle</h4>
        
      </div>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-md-6 px-5 px-md-0">
        
        <form v-if="product">
        
          <!-- <div class="form-group">
            <label>Kategory</label>
            <select class="form-control" v-model="product.categoryId" required>
              <option
                v-for="category of categories"
                :key="category.id"
                :value="category.id"
                >{{ category.categoryName }}</option
              >
            </select> 
          </div> -->
          <h1>{{product.name}}</h1>
          <div class="form-group">
              
            <label>Açıklama 1</label>
            <input
              type="text"
              class="form-control"
              v-model="content1"
              :maxlength="49"
              required
            />
          </div>
          <div class="form-group">
            <label>Açıklama 2</label>
            <input
              type="text"
              class="form-control"
              v-model="content2"
              :maxlength="49"
              required
            />
          </div>
          <div class="form-group">
            <label>Açıklama 3 </label>
            <input
              type="text"
              class="form-control"
              v-model="content3"
              :maxlength="49"
              required
            />
          </div>
          <div class="form-group">
            <label>Açıklama 4</label>
            <input
              type="text"
              class="form-control"
              v-model="content4"
              :maxlength="49"
              required
            />
          </div>
          <div class="form-group">
            <label>Açıklama 5</label>
            <input
              type="text"
              class="form-control"
              v-model="content5"
              :maxlength="49"
              required
            />
          </div>
          <button type="button" class="btn btn-primary" @click="editProduct">
            Güncelle
          </button>
        </form>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: "a",
      admin_token: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
    };
  },
  props: ["baseURL", "products", "categories"],
  methods: {
    async editProduct() {
      const editProduct = {
        content1: this.content1,
        content2: this.content2,
        content3: this.content3,
        content4: this.content4,
        content5: this.content5,
        product_id: this.product_id,
        admin_token: this.admin_token,
      };
      await axios({
        method: "post",
        url:
          `${this.baseURL}ProductContent/createProductContent.php?key=123`,
        data: JSON.stringify(editProduct),
      })
        .then((res) => {
          //sending the event to parent to handle
          this.$emit("fetchData");
          this.$router.push({ name: "AdminProduct" });
          swal({
            text: "Ürününüz Başarıyla Güncellendi",
            icon: "success",
            closeOnClickOutside: false,
          });
        })
        .catch((err) => console.log("err", err));
    },
  },
  mounted() {
    if (!localStorage.getItem("admin_token")) {
      this.$router.push({ name: "Signin" });
      return;
    }
    this.admin_token = localStorage.getItem("admin_token");
    this.id = this.$route.params.id;
    // console.log(content4)
    console.log("mounted");
    // this.product = this.products.find((product) => product.id == this.id);
  },
    created: function() {
      // console.log("created");
    this.product_id = this.$route.params.id;
    this.admin_token = localStorage.getItem("admin_token");
    const datas = {
      admin_token: this.admin_token,
      product_id : this.product_id,
    };
    axios({
      method: "post",
      url:
        `${this.baseURL}ProductContent/getByIdOurProductContent.php?key=123`,
      data: JSON.stringify(datas),
    })
      .then((response) => {
        // console.log(response.data.length);
        // console.log(response.data.content1);
        
        this.content1 = response.data.content1;
        this.content2 = response.data.content2;
        this.content3 = response.data.content3;
        this.content4 = response.data.content4;
        this.content5 = response.data.content5;
        // console.log(this.content1);
        // console.log(this.content2);
        // console.log(this.content3);
        // console.log(this.content4);
        // console.log(this.content5);
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}
</style>
