<template>
  <footer>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-3 col-6">
          <ul style="list-style-type: none">
            <li class="text-light font-weight-bold pb-2">Biz Kimiz?</li>
            <li><a class="footer-link font-weight-light" href="#">Hakkımızda</a></li>
            <!-- buraya sayfa eklenecek -->
            <li><a class="footer-link font-weight-light" href="#">Android Uygulamamız Yakında sizlerle</a><br></li>
            <li><a class="footer-link font-weight-light" href="#">iOS Uygulamamız Yakında sizlerle</a><br></li>
          </ul>
        </div>

        <div class="col-md-3 col-6">
          <ul style="list-style-type: none">
            <li class="text-light font-weight-bold">Bizimler İletişime Geçin</li>
            <li><a class="footer-link font-weight-light" href="#">Facebook</a></li>
            <li><a class="footer-link font-weight-light" href="#">Instagram</a><br></li>
          </ul>
        </div>

        <div class="col-md-3 col-6">
          <ul style="list-style-type: none">
            <li class="text-light font-weight-bold">Bizimle Para Kazanın</li>
            <!-- <router-link :to="{ name: 'AdminAppeal' }" class="btn btn-dark text-center mx-auto px-5 py-1 mb-2">Bizimle Çalışmak İster Misiniz?</router-link> -->
            <li><a class="footer-link font-weight-light" href="https://www.1sepetkayisi.aslanyazilimm.com/adminappeal">Bizimle Satışlar Yapın</a></li>
            <li><a class="footer-link font-weight-light" href="#">Ürünlerinizin Reklamını Yapın</a><br></li>
          </ul>
        </div>

        <div class="col-md-3 col-6">
          <ul style="list-style-type: none">
            <li class="text-light font-weight-bold">Size Yardım Edelim</li>
            <li><a class="footer-link font-weight-light" href="#">İade Merkezi</a></li>
            <li><a class="footer-link font-weight-light" href="#">100% Satın Alma Koruması</a><br></li>
            <li><a class="footer-link font-weight-light" href="#">Yardım</a><br></li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="text-center py-5">
            <img id="logo" src="../assets/icon.png" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name : "Footer"
}
</script>

<style>
footer {
  margin-top : 100px;
  background-color:#232F3E;
  font-size:16px;
}

li {
  padding-left: 0;
  padding-bottom: 10px;
}

a {
  text-decoration: none;
}

.footer-link {
  color: #DDD;
}

#logo {
  width: 150px;
}

footer {
  font-family: 'Roboto', sans-serif;
}


</style>
