<template>
  <Navbar
    :cartCount="cartCount"
    @resetCartCount="resetCartCount"
    v-if="!['Signup', 'Signin'].includes($route.name)"
  />
  <div style="min-height: 100vh ;">
    <router-view
      v-if="products && categories"
      :baseURL="baseURL"
      :products="products"
      :categories="categories"
      @fetchData="fetchData"
    >
    </router-view>
  </div>
  <Footer v-if="!['Signup', 'Signin'].includes($route.name)" />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  data() {
    return {
      baseURL: "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/",
      //baseURL: "http://localhost:8080/",
      products: null,
      categories: null,
      key: 0,
      token: null,
      cartCount: 0,
    };
  },

  components: { Footer, Navbar },
  methods: {
    async fetchData() {
      // fetch products
      await axios
        .get(
          `${this.baseURL}Product/getAllProducts.php?key=123`
        )
        .then((res) => (this.products = res.data.data))
        .catch((err) => console.log(err));

      //fetch categories
      await axios
        .get(
          `${this.baseURL}Category/getAllCategory.php?key=123`
        )
        .then((res) => (this.categories = res.data.data))
        .catch((err) => console.log(err));

      //fetch cart items

        this.token = localStorage.getItem("token");
        
        const token = {
          token: this.token,
        };

        await axios({
          method: "post",
          url:
            "https://www.api.1sepetkayisi.aslanyazilimm.com/AslanApi/Cart/getByUserCart.php?key=123",
          data: JSON.stringify(token),
        }).then(
          
          (response) => {
            
            if (response.status == 200) {
              this.cartCount = response.data;
            }else{
              this.cartCount = 0;
            }
            //console.log(this.cartCount);
          }
        ).catch((err) => console.log(err));;
      
    },
    resetCartCount() {
      this.cartCount = 0;
    },
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.fetchData();
  },
};
</script>

<style>
#background-div {
  background: url(assets/homepageMain2.png);
}
html {
  overflow-y: scroll;
}
</style>
